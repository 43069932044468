<template>
  <v-card class='rounded-lg elevation-3 ma-5 pa-5'>
    <v-card-title class='font-weight-bold headline pa-0 mb-4'>Page Editor</v-card-title>
    <v-card-text class='px-6 col-12 col-lg-6 mb-8 pa-0'>
      Editor allows the business admin to customize displayed text based on the role of the user as well as the side
      panel image and displayed text for the “Master Login Page.” Role-based edits are unique to each role.
    </v-card-text>
    <v-row class='px-6 justify-space-between flex-wrap px-3 role-buttons'>
      <v-btn
        class='cms-button mb-3'
        color='primary'
        depressed
        @click='$router.push({name: "GeneralSettingsPage"})'
      >
        Master Login Page
      </v-btn>
      <v-btn
        v-for='button in buttonNames'
        :key='button.id'
        class='cms-button mb-3'
        color='primary'
        depressed
        @click='goToEditPage(button.name, button.id)'
      >
        {{ button.name }}
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'AdminPage',
  data: () => ({
    buttonNames: [
      { name: 'Business Admin', id: 1 },
      { name: 'Member Admin', id: 2 },
      { name: 'Charity Admin', id: 3 },
      { name: 'Individual Employee', id: 4 },
      { name: 'Member Hierarchy', id: 5 }
    ]
  }),
  async created() {
    await this.getCMSContent()
  },
  methods: {
    ...mapActions('cmsStore', ['getCMSContent']),
    ...mapMutations('cmsStore', ['SELECT_ROLE_ID']),
    async goToEditPage(pageName, roleId) {
      const pathParams = pageName.replace(' ', '-').toLowerCase()
      this.SELECT_ROLE_ID(roleId)
      await this.$router.push({ name: 'EditPage', params: { pageName: pathParams } })
    }
  }
}
</script>

<style lang='scss' scoped>
@media (max-width: 1500px) {
  .cms-button {
    width: 32%;
  }
}
@media (max-width: 1265px) {
  .cms-button {
    width: 48%;
  }
}
@media (max-width: 790px) {
  .role-buttons {
    justify-content: center;
  }
  .cms-button {
    width: 100%;
  }
}
</style>
